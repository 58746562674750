import { initializeApp } from "firebase/app";
import {
  getAuth,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import * as firebaseui from "firebaseui";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyD22zHi3XACWdDn3ZD7eyI4jveRXrvAx9o",
  authDomain: "app.cinnamon.ninja",
  projectId: "fir-web-codelab-40db2",
  storageBucket: "fir-web-codelab-40db2.appspot.com",
  messagingSenderId: "419475535921",
  appId: "1:419475535921:web:24d0dc13e30658d48abd10",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

// Common UI elements
export const logoutButton = document.getElementById("logout");
export const appContainer = document.getElementById("app");
export const menuButton = document.getElementById("menu");
export const navigationBar = document.getElementById("navigation");

export const copFormat = new Intl.NumberFormat("es-CO", {
  style: "currency",
  currency: "COP",
  maximumFractionDigits: 0,
  minimumFractionDigits: 0,
});

// Firebase UI configuration
export const uiConfig = {
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
  signInOptions: [GoogleAuthProvider.PROVIDER_ID],
  callbacks: {
    signInSuccessWithAuthResult: function (authResult, redirectUrl) {
      return false;
    },
  },
};

export function initializeAuth() {
  const ui = new firebaseui.auth.AuthUI(auth);
  logoutButton.addEventListener("click", () => {
    ui.reset();
    if (auth.currentUser) {
      signOut(auth);
    } else {
      ui.start("#firebaseui-auth-container", uiConfig);
    }
  });

  return ui;
}

export function setupMenuButton() {
  menuButton.addEventListener("click", (e) => {
    navigationBar.style.display =
      navigationBar.style.display !== "flex" ? "flex" : "none";
    appContainer.style.marginLeft =
      navigationBar.style.display === "flex"
        ? navigationBar.getBoundingClientRect().width + "px"
        : "0";
  });
}

export function setupSwipeHandler() {
  let touchStartX = 0;
  let touchEndX = 0;
  let touchStartY = 0;
  let touchEndY = 0;

  document.body.addEventListener(
    "touchstart",
    (e) => {
      touchStartX = e.changedTouches[0].screenX;
      touchStartY = e.changedTouches[0].screenY;
    },
    false,
  );

  document.body.addEventListener(
    "touchend",
    (e) => {
      touchEndX = e.changedTouches[0].screenX;
      touchEndY = e.changedTouches[0].screenY;
      handleSwipe();
    },
    false,
  );

  function handleSwipe() {
    const deltaX = touchEndX - touchStartX;
    const deltaY = touchEndY - touchStartY;
    const horizontalSwipeThreshold = 80;

    if (
      Math.abs(deltaX) > Math.abs(deltaY) &&
      Math.abs(deltaX) > horizontalSwipeThreshold
    ) {
      navigationBar.style.display = deltaX > 0 ? "flex" : "none";
      appContainer.style.marginLeft =
        navigationBar.style.display === "flex"
          ? navigationBar.getBoundingClientRect().width + "px"
          : "0";
    }
  }
}
