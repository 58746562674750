import {
  auth,
  db,
  logoutButton,
  appContainer,
  menuButton,
  copFormat,
  initializeAuth,
  setupMenuButton,
  setupSwipeHandler,
  uiConfig,
} from "./main.js";

import {
  getAuth,
  GoogleAuthProvider,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import {
  getFirestore,
  addDoc,
  getDocs,
  getDoc,
  collection,
  query,
  where,
  orderBy,
  onSnapshot,
  deleteDoc,
  updateDoc,
  doc,
  limit,
} from "firebase/firestore";

import * as firebaseui from "firebaseui";

// Document elements
const form = document.getElementById("create-expense");
const inputAmount = document.getElementById("amount");
const inputReason = document.getElementById("reason");
const paidBy = document.getElementById("paid-by");
const inputPaymentMethod = document.getElementById("payment-method");
const inputIsPrivate = document.getElementById("is-private");
const expenses = document.getElementById("expenses");
const showMoreButton = document.getElementById("show-more-transactions");
let transactionsToDisplay = 5;

let queryListener = null;

main();

async function main() {
  function loadLogin() {
    ui.start("#firebaseui-auth-container", uiConfig);
    logoutButton.style.display = "none";
    menuButton.style.display = "none";
    appContainer.style.display = "none";
  }

  function loadUserApp() {
    logoutButton.style.display = "flex";
    menuButton.style.display = "flex";
    appContainer.style.display = "flex";
    subscribeExpenses(transactionsToDisplay);
    form.addEventListener("submit", addOrUpdateExpense);
    paidBy.value = auth.currentUser.uid;
    document.getElementById("firebaseui-auth-container").innerHTML = "";
    addPaymentMethods();
  }
  const ui = initializeAuth();
  setupMenuButton();
  setupSwipeHandler();
  if (auth.currentUser) {
    loadUserApp();
  }

  onAuthStateChanged(auth, (user) => {
    if (user) {
      loadUserApp();
    } else {
      loadLogin();
    }
  });
}

function increaseTransactionsDisplayed() {
  transactionsToDisplay += 5;
  subscribeExpenses(transactionsToDisplay);
}

function displayExpenses(snapsToDisplay) {
  // Create an HTML entry for each document and add it to the expense container
  snapsToDisplay.forEach((expense) => {
    const date = new Date(expense.data().timestamp);
    const entry = document.createElement("p");
    entry.classList.add("expense-card");
    entry.setAttribute("data-expense-id", expense.id);
    entry.innerHTML =
      `<button class="remove-expense" expense-id="${expense.id}"> <i class="material-icons" expense-id="${expense.id}">delete</i></button>` +
      "<b>" +
      copFormat.format(expense.data().amount) +
      "<br>" +
      expense.data().reason +
      "</b><br> Paid by <b>" +
      expense.data().name +
      "</b> on " +
      date.toLocaleDateString("es-CO");

    expenses.appendChild(entry);

    // Add click event listener to the expense card
    entry.addEventListener("click", populateExpenseForm);
  });
}

function subscribeExpenses(numberOfTransactions) {
  const q = query(
    collection(db, "expenses"),
    where("private", "!=", true),
    orderBy("timestamp", "desc"),
  );

  showMoreButton.addEventListener("click", increaseTransactionsDisplayed);
  queryListener = onSnapshot(q, (snaps) => {
    // Reset page
    expenses.innerHTML = "";
    const totalsPerUser = {};
    let total = 0;
    const snapsToDisplay = snaps.docs.slice(0, numberOfTransactions);
    // Loop through documents in database
    displayExpenses(snapsToDisplay);
    snaps.forEach((expense) => {
      if (totalsPerUser[expense.data().name]) {
        totalsPerUser[expense.data().name] += parseInt(expense.data().amount);
      } else {
        totalsPerUser[expense.data().name] = parseInt(expense.data().amount);
      }
      total += parseInt(expense.data().amount);
    });
    let maxUser = {};
    const entry = document.createElement("p");
    for (let user in totalsPerUser) {
      if (!maxUser["name"] || totalsPerUser[user] > maxUser["amount"]) {
        maxUser["name"] = user;
        maxUser["amount"] = parseInt(totalsPerUser[user]);
      }
    }

    entry.innerHTML += `<p><b>${
      maxUser["name"]
    }</b> has a positive balance of: <b>${copFormat.format(
      parseInt(maxUser["amount"] - total / Object.keys(totalsPerUser).length),
    )}</b></p>`;
    expenses.insertBefore(entry, expenses.firstChild);
    addRemoveExpenseListeners();
  });
}

function unsubscribeQuery() {
  if (queryListener != null) {
    queryListener();
    queryListener = null;
  }
}

function addRemoveExpenseListeners() {
  let buttons = document.getElementsByClassName("remove-expense");
  for (let i = 0; i < buttons.length; i++) {
    buttons[i].addEventListener("click", removeExpense);
  }
}

async function removeExpense(e) {
  e.preventDefault();
  e.stopPropagation();
  if (confirm("Are you sure you want to delete this expense?")) {
    deleteDoc(doc(db, "expenses", e.target.getAttribute("expense-id")))
      .then()
      .catch((e) => alert(e));
  }
}

//pull cards for paymentMethod field
async function addPaymentMethods() {
  const q = query(
    collection(db, "cards"),
    where("userId", "==", auth.currentUser.uid),
    where("inactive", "!=", true),
  );
  const cards = await getDocs(q);
  cards.forEach((card) => {
    let option = document.createElement("option");
    option.text = `${card.data().name} ${card.data().lastFourDigits}`;
    option.value = card.id;
    inputPaymentMethod.add(option);
  });
}

async function populateExpenseForm(e) {
  const expenseId = e.currentTarget.getAttribute("data-expense-id");
  const expenseData = await getExpenseData(expenseId);

  inputAmount.value = expenseData.amount;
  inputReason.value = expenseData.reason;
  paidBy.value = expenseData.userId;
  inputIsPrivate.checked = expenseData.private;

  // Add hidden input for expense ID if it doesn't exist
  let hiddenExpenseId = document.getElementById("hidden-expense-id");
  if (!hiddenExpenseId) {
    hiddenExpenseId = document.createElement("input");
    hiddenExpenseId.type = "hidden";
    hiddenExpenseId.id = "hidden-expense-id";
    form.appendChild(hiddenExpenseId);
  }
  hiddenExpenseId.value = expenseId;

  // Change submit button text
  const submitButton = form.querySelector("button[type='submit']");
  submitButton.textContent = "Update Expense";
  window.scrollTo(0, 0);
}

async function getExpenseData(expenseId) {
  try {
    const docRef = doc(db, "expenses", expenseId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      return {
        amount: data.amount,
        reason: data.reason,
        userId: data.userId,
        private: data.private,
      };
    } else {
      console.log("No such document!");
      return null;
    }
  } catch (error) {
    console.error("Error getting document:", error);
    return null;
  }
}

async function addOrUpdateExpense(e) {
  e.preventDefault();
  let paidByName = paidBy.options[paidBy.selectedIndex].getAttribute("name");
  console.log("Paid by name:");
  console.log(paidByName);
  const hiddenExpenseId = document.getElementById("hidden-expense-id");

  if (hiddenExpenseId && hiddenExpenseId.value) {
    // Update existing expense
    const expenseData = {
      reason: inputReason.value,
      amount: inputAmount.value,
      name: paidByName,
      userId: paidBy.value,
      private: inputIsPrivate.checked,
    };
    console.log("Updating expense:");
    console.log(expenseData);
    await updateDoc(doc(db, "expenses", hiddenExpenseId.value), expenseData);
  } else {
    // Add new expense
    const expenseData = {
      reason: inputReason.value,
      amount: inputAmount.value,
      timestamp: Date.now(),
      name: paidByName,
      userId: paidBy.value,
      private: inputIsPrivate.checked,
      paymentMethod: inputPaymentMethod.value,
      paid: false,
    };
    console.log("Adding expense:");
    console.log(expenseData);
    await addDoc(collection(db, "expenses"), expenseData);
  }

  // Clear form and reset submit button
  clearForm();
}

function clearForm() {
  console.log("Clearing form");
  inputAmount.value = "";
  inputReason.value = "";
  inputIsPrivate.checked = false;
  inputPaymentMethod.value = "Cash";
  paidBy.value = auth.currentUser.uid;
  const hiddenExpenseId = document.getElementById("hidden-expense-id");
  if (hiddenExpenseId) {
    hiddenExpenseId.value = "";
  }

  const submitButton = form.querySelector("button[type='submit']");
  submitButton.innerHTML = `<i class="material-icons">add_circle</i>
            <span>Create</span>`;
}
